import spiele from "../assets/json/spiele.json"
import LoL from "../assets/img/games/SW1024/LoL.png";
import LoLBig from "../assets/img/games/SW1024/LoLBig.png";
import CS from "../assets/img/games/SW1024/CS.png";
import RL from "../assets/img/games/SW1024/RL.png";
import RLBig from "../assets/img/games/SW1024/RLBig.png";
import R6 from "../assets/img/games/SW1024/R6.png";
import R6Big from "../assets/img/games/SW1024/R6Big.png";
import Valo from "../assets/img/games/SW1024/Valo.png";
import ValoBig from "../assets/img/games/SW1024/ValoBig.png";
import OW from "../assets/img/games/SW1024/OW.png";
import OWBig from "../assets/img/games/SW1024/OWBig.png";
// import Sim from "../assets/img/games/SW1024/sim.png";
// import SimBig from "../assets/img/games/SW1024/simBig.png";
import {ImgDropShadow} from "../Components/specials/ImgDropShadow";
import {useCallback, useEffect, useState} from "react";
import {DepartmentInfoCard} from "../Components/specials/DepartmentInfoCard";
import {DepartmentTeamCard} from "../Components/specials/DepartmentTeamCard";
import {Game} from "../types/game";
import { useNavigate } from 'react-router-dom';
import {
    GAMES_CS_ROUTE,
    GAMES_LOL_ROUTE,
    GAMES_OW_ROUTE,
    GAMES_R6_ROUTE,
    GAMES_RL_ROUTE,
    // GAMES_SIM_ROUTE,
    GAMES_VALO_ROUTE
} from "../types/routes";


interface SelectedGame {
    gameName: Game
}

type GameToRouteMap = { [key in Game]: string };

export const Departments = (props: SelectedGame) => {

    const navigate = useNavigate();
    // @ts-ignore
    const [currentGame, setCurrentGame] = useState(spiele.abteilungen[props.gameName]);
    const [currentGameLogoBig, setCurrentGameLogoBig] = useState(LoLBig);

    const changeGame = useCallback((game: Game) => {
        // Define a route for each Game enumeration.
        const gameToRouteMap: GameToRouteMap = {
            [Game.LOL]: GAMES_LOL_ROUTE,
            [Game.CS]: GAMES_CS_ROUTE,
            [Game.RL]: GAMES_RL_ROUTE,
            [Game.R6]: GAMES_R6_ROUTE,
            [Game.VALO]: GAMES_VALO_ROUTE,
            [Game.OW]: GAMES_OW_ROUTE
            // ,
            // [Game.SIM]: GAMES_SIM_ROUTE
        }

        function setConfigurationAccordingToGame (department: any, image: string, game: Game) {
            setCurrentGame(department);
            setCurrentGameLogoBig(image);
            navigate(gameToRouteMap[game]);
        }

        switch (game) {
            case Game.LOL: {
                setConfigurationAccordingToGame(spiele.abteilungen.LoL, LoLBig, game)
                break;
            }
            case Game.CS: {
                setConfigurationAccordingToGame(spiele.abteilungen.CS, CS, game)
                break;
            }
            case Game.RL: {
                setConfigurationAccordingToGame(spiele.abteilungen.RL, RLBig, game)
                break;
            }
            case Game.R6: {
                setConfigurationAccordingToGame(spiele.abteilungen.R6, R6Big, game)
                break;
            }
            case Game.VALO: {
                setConfigurationAccordingToGame(spiele.abteilungen.Valo, ValoBig, game)
                break;
            }
            case Game.OW: {
                setConfigurationAccordingToGame(spiele.abteilungen.OW, OWBig, game)
                break;
            }
            // case Game.SIM: {
            //     setConfigurationAccordingToGame(spiele.abteilungen.Sim, SimBig, game)
            //     break;
            // }
        }
    }, [navigate]);

    useEffect(() => {
        changeGame(props.gameName);
    }, [props.gameName, changeGame]);

    const isGame = (game:string) => {
        return game === currentGame.abteilung;
    }

    return (
        <>
            <div className="spiele ContentWrapper">
                <div className="spiele-abteilungen">
                    <span className="games-imgs">
                        <span className="game-img" onClick={() => changeGame(Game.LOL)}>
                            <ImgDropShadow img={LoL} alt={""} invert={true} selected={isGame(spiele.abteilungen.LoL.abteilung)} />
                        </span>
                        <span className="game-img" onClick={() => changeGame(Game.CS)} >
                            <ImgDropShadow img={CS} alt={""} invert={true} selected={isGame(spiele.abteilungen.CS.abteilung)}/>
                        </span>
                        <span className="game-img" onClick={() => changeGame(Game.RL)}>
                            <ImgDropShadow img={RL} alt={""} invert={true} selected={isGame(spiele.abteilungen.RL.abteilung)}/>
                        </span>
                        <span className="game-img" onClick={() => changeGame(Game.R6)}>
                            <ImgDropShadow img={R6} alt={""} invert={true} selected={isGame(spiele.abteilungen.R6.abteilung)}/>
                        </span>
                        <span className="game-img" onClick={() => changeGame(Game.VALO)}>
                            <ImgDropShadow img={Valo} alt={""} invert={true} selected={isGame(spiele.abteilungen.Valo.abteilung)}/>
                        </span>
                        <span className="game-img" onClick={() => changeGame(Game.OW)}>
                            <ImgDropShadow img={OW} alt={""} invert={true} selected={isGame(spiele.abteilungen.OW.abteilung)}/>
                        </span>
                        {/* <span className="game-img" onClick={() => changeGame(Game.SIM)}>
                            <ImgDropShadow img={Sim} alt={""} invert={true} selected={isGame(spiele.abteilungen.Sim.abteilung)}/>
                        </span> */}
                    </span>
                </div>
                <span className="spiele-hr"><hr/></span>
                <div className="spiele-content">
                    <div>
                        <DepartmentInfoCard abteilung={currentGame.abteilung } 
                                            elo={currentGame.elobereich} 
                                            liegen={currentGame.liegen} 
                                            teamCount={currentGame.teams.length} 
                                            abteilungsleitung={currentGame.abteilungsleitung}
                                            img={currentGameLogoBig}/>
                    </div>
                    <div className="spiele-beschreibung">
                    <br/>{currentGame.beschreibung}<br/>
                    </div>
                    <div className="spiele-teams">
                        {
                            currentGame.teams.map((team: { name: string; Liga: string; Division: number; spieler: string[]; }) => {
                                return (<DepartmentTeamCard name={team.name} Liga={team.Liga} Division={team.Division} spieler={team.spieler}/>)
                            })
                        }
                    </div>
                    </div>
            </div>
        </>
    );
}