import { ImgLeft } from "../Components/specials/ImgLef";
import { Polaroid } from "../Components/specials/Polaroid";
import b1 from "../assets/img/ueberuns/esport.png";
import b2 from "../assets/img/ueberuns/beerpong.jpg";
import { ImgRight } from "../Components/specials/ImgRight";
import { ImgDropShadow } from "../Components/specials/ImgDropShadow";
import wellen from "../assets/img/wellenmithai.png"
import p1 from "../assets/img/partner/BAG_Logo_wei_1200.png";
import p2 from "../assets/img/partner/TK-Logo_Koop_Gesundheitsspartner_neg.png";
import p3 from "../assets/img/partner/Otto-Logo.png";
import p4 from "../assets/img/partner/Asta-TUHH.png";
import LoLImage from "../assets/img/games/SW1024/LoLBig.png";
import CSImage from "../assets/img/games/SW1024/CS.png";
import R6Image from "../assets/img/games/SW1024/R6Big.png";
import RLImage from "../assets/img/games/SW1024/RLBig.png";
import ValoImage from "../assets/img/games/SW1024/ValoBig.png";
import OWImage from "../assets/img/games/SW1024/OWBig.png";
// import SimImage from "../assets/img/games/SW1024/simBig.png";
import { Link } from "react-router-dom";
import {GAMES_CS_ROUTE, GAMES_LOL_ROUTE, GAMES_OW_ROUTE, GAMES_R6_ROUTE, GAMES_RL_ROUTE, 
    // GAMES_SIM_ROUTE, 
    GAMES_VALO_ROUTE} from "../types/routes";
export const Home = () => {


    return (
        <>
        <div className='home ContentWrapper'>
            <div className='content-element'>
                <div className='content-element-left'>
                    <div className='home-title'>
                        <span className='home-title-text'>E-SPORT</span>
                        <span className='home-title-text-special'>AUS HAMBURG</span>
                        <span className='home-title-text-special'>FÜR HAMBURG</span>
                    </div>
                </div>
                <div className='content-element-right'>
                    <div className="element-polaroid">
                        <Polaroid />
                    </div>
                </div>
            </div>
            <div className='content-element content-element-first'>
                <div className="content-element-left onlydesktop">
                    <ImgLeft img={b1} alt="bild" />
                </div>
                <div className="content-element-right">
                    <h2>E-Sport</h2>
                    <p className="home-text">
                    Wir wollen Studierenden in und um Hamburg die Möglichkeit bieten, in verschiedenen Spielen mit anderen Studierenden, die Hamburger Unis in deutschen Wettbewerben zu vertreten. Unser Fokus liegt dabei auf der Uniliga. Jeder Studierende einer Hamburger Uni ist bei uns willkommen.
                    </p>
                </div>
                <div className="content-element-left onlymobile">
                    <ImgLeft img={b1} alt="bild" />
                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-left">
                    <h2>Community</h2>
                    <p className="home-text">
                    Neben dem Spielbetrieb möchten wir auch eine Community schaffen, in der sich Hamburger Studierende untereinander über ihre Esport-Leidenschaft austauschen können. Hierfür veranstalten wir regelmäßig Online- und Offline-Events, wie z.B. Community Treffen, kleinere Spaßturniere, Public Viewing und vieles mehr.
                    </p>
                </div>
                <div className="content-element-right">
                    <ImgRight img={b2} alt="bild" />

                </div>
            </div>
            <div className='content-element content-element-rest'>
                <div className="content-element-rest-games">
                    <span><h2>Unsere Games</h2></span>
                    <span>
                    <div className="home-game-logos">
                        <Link to={GAMES_LOL_ROUTE}><ImgDropShadow img={LoLImage} alt="" invert={true} /></Link>
                        <Link to={GAMES_CS_ROUTE}><ImgDropShadow img={CSImage} alt="" invert={true} /></Link>
                        <Link to={GAMES_R6_ROUTE}><ImgDropShadow img={R6Image} alt="" invert={true} /></Link>
                        <Link to={GAMES_RL_ROUTE}><ImgDropShadow img={RLImage} alt="" invert={true} /></Link>
                        <Link to={GAMES_VALO_ROUTE}><ImgDropShadow img={ValoImage} alt="" invert={true} /></Link>
                        <Link to={GAMES_OW_ROUTE}><ImgDropShadow img={OWImage} alt="" invert={true} /></Link>
                        {/* <Link to={GAMES_SIM_ROUTE}><ImgDropShadow img={SimImage} alt="" invert={true} /></Link> */}
                    </div>
                    </span>
                    
                </div>

            </div>
        </div>
        <div className="Footer">
        <img src={wellen} alt=""/>
        <div className="Footer-Partner">
            <div className="Footer-Partner-Wrapper">
            <div className="Footer-Partner-content">
                <h3>Unsere Partner</h3>
                <p>
                Ein herzliches Dankeschön an unsere Partner, die uns tatkräftig unterstützen!
                </p>
            </div>
            <div className="Footer-Partner-logos">
                <a href="https://www.brockhaus-ag.de/" target="_blank" rel="noreferrer"><ImgDropShadow img={p1} alt="Brockhaus"/></a>
                <a href="https://www.tk.de/" target="_blank" rel="noreferrer"><ImgDropShadow img={p2} alt="TK"/></a>
                <a href="https://www.otto.de/jobs/de/" target="_blank" rel="noreferrer"><ImgDropShadow img={p3} alt="OTTO"/></a>
                <a href="https://www.asta.tuhh.de/" target="_blank" rel="noreferrer"><ImgDropShadow img={p4} alt="AStA TUHH"/></a>
            </div>
            </div>
        </div>
    </div>
        </>
    );
}